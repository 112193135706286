var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section__lk-menu-mobile d-sm-none"},[_c('div',{staticClass:"toggle"}),(_vm.settingsRoutes.indexOf(_vm.$route.name) > -1)?[_vm._m(0),_c('div',{staticClass:"section__lk-submenu"},[_c('div',[_c('router-link',{class:{'active': _vm.$route.name === 'ProfileSettings'},attrs:{"to":"/lk/settings"}},[_vm._v("Личные данные")])],1),_c('div',[_c('router-link',{attrs:{"to":"/lk/company"}},[_vm._v("Данные о компании")])],1),_c('div',[_c('router-link',{attrs:{"to":"/lk/subscribes"}},[_vm._v("Рассылки и подписки")])],1)])]:_vm._e(),(_vm.$route.name === 'Seminars')?[_vm._m(1)]:_vm._e(),(_vm.$route.name === 'Online' || _vm.$route.name === 'Test')?[_vm._m(2)]:_vm._e(),(_vm.$route.name === 'OnlineRequests' || _vm.$route.name === 'OfflineRequests')?[_vm._m(3),_c('div',{staticClass:"section__lk-submenu"},[_c('div',[_c('router-link',{class:{'active': _vm.$route.name === 'OnlineRequests'},attrs:{"to":"/lk/requests/online"}},[_vm._v("Онлайн программа")])],1),_c('div',[_c('router-link',{class:{'active': _vm.$route.name === 'OfflineRequests'},attrs:{"to":"/lk/requests/offline"}},[_vm._v("Семинары")])],1)])]:_vm._e(),(_vm.$route.name === 'OnlineDocs' || _vm.$route.name === 'OfflineDocs')?[_vm._m(4),_c('div',{staticClass:"section__lk-submenu"},[_c('div',[_c('router-link',{class:{'active': _vm.$route.name === 'OnlineDocs'},attrs:{"to":"/lk/docs/online"}},[_vm._v("Онлайн программа")])],1),_c('div',[_c('router-link',{class:{'active': _vm.$route.name === 'OfflineDocs'},attrs:{"to":"/lk/docs/offline"}},[_vm._v("Семинары")])],1)])]:_vm._e(),(_vm.$route.name === 'Ebs')?[_vm._m(5)]:_vm._e(),(_vm.$route.name === 'Libs')?[_vm._m(6)]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('img',{attrs:{"src":"/pic/lk-settings.svg"}}),_vm._v("Настройки")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('img',{attrs:{"src":"/pic/lk-seminar.svg"}}),_vm._v("Семинары")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('img',{attrs:{"src":"/pic/lk-programm.svg"}}),_vm._v("Онлайн программа")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('img',{attrs:{"src":"/pic/lk-order.svg"}}),_vm._v("Мои заявки")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('img',{attrs:{"src":"/pic/lk-doc.svg"}}),_vm._v("Мои документы")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('img',{attrs:{"src":"/pic/lk-elib.svg"}}),_vm._v("Электронная библиотека")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('img',{attrs:{"src":"/pic/lk-elib.svg"}}),_vm._v("Библиотека")])
}]

export { render, staticRenderFns }