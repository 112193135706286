<template>
  <ul>
    <template v-if="mspForsageOperator">
      <li v-for="(menu, index) in mspForsageOperator.programs" :key="index"  :class="{'active': ['MspForsageOperatorOrders', 'MspForsageGroups'].indexOf($route.name) > -1 && menu.id == currentProgram}">
        <router-link :to="`/lk/msp-forsage/orders?program=${menu.id}`"><img src="/pic/lk-accelerator-menu.svg">{{ menu.name }}</router-link>
        <div class="section__lk-submenu">
          <div><router-link :to="`/lk/msp-forsage/orders?program=${menu.id}`">Новые заявки</router-link></div>
          <div><router-link :to="`/lk/msp-forsage/groups?program=${menu.id}`">Потоки обучения</router-link></div>
        </div>
      </li>
    </template>
    <li :class="{'active': settingsRoutes.indexOf($route.name) > -1}">
      <router-link to="/lk/settings"><img src="/pic/lk-settings.svg">Настройки</router-link>
      <div class="section__lk-submenu">
        <div><router-link to="/lk/settings" :class="{'active': $route.name === 'ProfileSettings'}">Личные данные</router-link></div>
        <div><router-link to="/lk/company">Данные о компании</router-link></div>
        <div><router-link to="/lk/subscribes">Рассылки и подписки</router-link></div>
      </div>
    </li>
  </ul>
</template>

<script>


import mspForsageOperatorMixin from "@/mixins/mspForsageOperatorMixin";
import roleRoutersMixin from "@/mixins/roleRoutersMixin";

export default {
  name: "ProfileMenuMspForsageOperator",
  mixins: [mspForsageOperatorMixin, roleRoutersMixin]
}
</script>

<style scoped>

</style>